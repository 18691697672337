import 'core-js/stable';
import 'regenerator-runtime/runtime';
import rome from '@bevacqua/rome';
import MicroModal from 'micromodal';
import SmoothScroll from 'smooth-scroll';
import { createPopper } from "@popperjs/core";
import './vendor/closest';
import './vendor/collapsible';

const handleUnload = function(e) {
  // Cancel the event
  e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  e.returnValue = '';
};

window.addEventListener('beforeunload', handleUnload);

document.addEventListener('DOMContentLoaded', function() {
  const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    topOnEmptyHash: false,
    updateURL: false,
  });

  MicroModal.init();

  const definitionTriggers = document.querySelectorAll('[data-definition]');
  let poppers = [];

  definitionTriggers.forEach(function(definitionTrigger) {
    const definitionKey = definitionTrigger.getAttribute('data-definition'),
          definitionNode = document.getElementById('definition-' + definitionKey),
          definitionArrow = document.querySelector('#definition-' + definitionKey + ' .definition-arrow');

    if (definitionNode) {
      const popper = createPopper(definitionTrigger, definitionNode, {
        placement: 'right',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 12],
            },
          },
          {
            name: 'arrow',
            options: {
              element: definitionArrow,
            },
          },
        ],
      });

      poppers.push(popper);

      function show() {
        definitionNode.setAttribute('data-show', '');
      }
      
      function hide() {
        definitionNode.removeAttribute('data-show');
      }
      
      const showEvents = ['mouseenter', 'focus'];
      const hideEvents = ['mouseleave', 'blur'];
      
      showEvents.forEach(event => {
        definitionTrigger.addEventListener(event, show);
      });
      
      hideEvents.forEach(event => {
        definitionTrigger.addEventListener(event, hide);
      });
    }
  });

  function refreshPoppers() {
    poppers.forEach(function(popper) {
      popper.update();
    });
  };

  function serialize(form) {
    let serialized = [];

    for (let i = 0; i < form.elements.length; i++) {
      const field = form.elements[i];

      if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

      if (field.type === 'select-multiple') {
        for (let n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue;
          serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
        }
      } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
        serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
      }
    }

    return serialized.join('&');
  };

  function prepareResults() {
    const fieldResults = document.querySelectorAll('[data-field-result]'),
          fieldResultsMultiple = document.querySelectorAll('[data-field-result-multiple][data-field-result-multiple-type]');

    fieldResults.forEach(function(fieldResult) {
      const fieldName = fieldResult.getAttribute('data-field-result'),
            fieldShowIf = fieldResult.getAttribute('data-field-show-if'),
            fieldWrapper = fieldResult.closest('.result'),
            fieldGroupWrapper = fieldResult.closest('.result-group'),
            fieldSectionWrapper = fieldResult.closest('.result-section');

      let field = document.querySelector('[name="' + fieldName + '"]');

      if (field) {
        if (field.type == 'radio') {
          field = document.querySelector('[name="' + fieldName + '"]:checked');
        }
  
        if (field && field.value) {
          if (fieldShowIf) {
            if (fieldShowIf == field.value) {
              if (fieldWrapper) {
                fieldWrapper.classList.add('filled');
              }
              if (fieldGroupWrapper) {
                fieldGroupWrapper.classList.add('filled');
              }
              if (fieldSectionWrapper) {
                fieldSectionWrapper.classList.add('filled');
              }
            }
          } else {
            fieldResult.innerHTML = field.value.replace(/\r?\n/g, '<br />');
  
            if (fieldWrapper) {
              fieldWrapper.classList.add('filled');
            }
            if (fieldGroupWrapper) {
              fieldGroupWrapper.classList.add('filled');
            }
            if (fieldSectionWrapper) {
              fieldSectionWrapper.classList.add('filled');
            }
          }
        }
      }
    });

    fieldResultsMultiple.forEach(function(fieldResult) {
      const fieldName = fieldResult.getAttribute('data-field-result-multiple'),
            fieldWrapper = fieldResult.closest('.result'),
            fieldGroupWrapper = fieldResult.closest('.result-group'),
            fieldSectionWrapper = fieldResult.closest('.result-section'),
            multipleType = fieldResult.getAttribute('data-field-result-multiple-type'),
            sortAlphabetically = fieldResult.getAttribute('data-field-result-multiple-type'),
            fields = document.querySelectorAll('[name^="' + fieldName + '["][type="radio"]:checked, [name^="' + fieldName + '["][type="checkbox"]:checked, [name^="' + fieldName + '["][type="text"], [name^="' + fieldName + '["][data-custom-checkbox-option]'),
            regExp = /\[[^\]]+\]/g,
            keyWithPrefixSuffixFields = {},
            nestedItemsFields = {};

      let showField = false;

      if (fields && fields.length > 0) {
        fields.forEach(function(field) {
          if (field.value != '') {
            showField = true;

            let matches = [],
                match = regExp.exec(field.getAttribute('name'));

            while (match != null) {
                matches.push(match[0]);
                match = regExp.exec(field.getAttribute('name'));
            }

            let li = document.createElement('li');

            if (multipleType == 'list') {
              const liTextNode = document.createTextNode(field.value);
              li.appendChild(liTextNode);
              fieldResult.appendChild(li);
            } else if (multipleType == 'nestedItems') {
              if (matches.length > 0) {
                const key = matches[0].replace(/[\[\]]/g, '');

                if (!nestedItemsFields[key]) {
                  nestedItemsFields[key] = []
                }

                nestedItemsFields[key].push(field.value);
              }
            } else if (multipleType == 'keyWithPrefixSuffix') {
              if (matches.length > 0) {
                const key = matches[0].replace(/[\[\]]/g, ''),
                      position = matches[1].replace(/[\[\]]/g, '');

                if (!keyWithPrefixSuffixFields[key]) {
                  keyWithPrefixSuffixFields[key] = {}
                }

                keyWithPrefixSuffixFields[key][position] = field.value;
              }
            }
          }
        });

        if (multipleType == 'nestedItems') {
          Object.keys(nestedItemsFields).forEach(function(key) {
            let li = document.createElement('li'),
                ul = document.createElement('ul'),
                liContent = key,
                values = nestedItemsFields[key];

            values.forEach(function(value) {
              let liTextNode = document.createTextNode(value),
                  li = document.createElement('li');

              li.appendChild(liTextNode);
              ul.appendChild(li);
            });

            const liTextNode = document.createTextNode(liContent);
            li.appendChild(liTextNode);
            li.appendChild(ul);
            fieldResult.appendChild(li);
          });
        } else if (multipleType == 'keyWithPrefixSuffix') {
          Object.keys(keyWithPrefixSuffixFields).forEach(function(key) {
            let li = document.createElement('li'),
                liContent = key,
                values = keyWithPrefixSuffixFields[key];

            if (values['Prefix2']) {
              liContent = values['Prefix2'] + ' - ' + liContent;
            }

            if (values['Prefix']) {
              liContent = values['Prefix'] + ' - ' + liContent;
            }

            if (values['Suffix']) {
              liContent = liContent + ' - ' + values['Suffix'];
            }

            if (values['Suffix2']) {
              liContent = liContent + ' - ' + values['Suffix2'];
            }

            const liTextNode = document.createTextNode(liContent);
            li.appendChild(liTextNode);
            fieldResult.appendChild(li);
          });
        }

        if (sortAlphabetically) {
          // get html children elements of li
          // in case of ul children will be li
          // ` Array.from` will hell helps to convert them into array
          var elements = Array.from(fieldResult.children);
        
          elements.sort(function(a, b){
            var nameA = a.innerText.toLowerCase(), nameB = b.innerText.toLowerCase();
            if (nameA < nameB) //sort string ascending
             return -1;
            if (nameA > nameB)
             return 1;
            return 0; //default return value (no sorting)
           });
        
          // append back to update the order
          // forEach can be used to update since it's in array format
          elements.forEach(function(ele) {
            fieldResult.appendChild(ele)
          });
        }

        if (showField && fieldWrapper) {
          fieldWrapper.classList.add('filled');
        }
        if (showField && fieldGroupWrapper) {
          fieldGroupWrapper.classList.add('filled');
        }
        if (showField && fieldSectionWrapper) {
          fieldSectionWrapper.classList.add('filled');
        }
      }
    });
  }

  function loadSection(section) {
    const activeSections = document.querySelectorAll('.section.active'),
          collapsibles = section.querySelectorAll('[data-collapsible]:not(.collapsible-init)');

    activeSections.forEach(function(activeSection) {
      activeSection.classList.remove('active');
    });

    section.classList.add('active');

    new Collapsible({
      node: collapsibles,
      eventNode: '[data-collapsible-header]',
      isCollapsed: true,
      observe: true,
      expandCallback: function() {
        setTimeout(function() {
          refreshPoppers();
        }, 300);
      },
      collapseCallback: function() {
        setTimeout(function() {
          refreshPoppers();
        }, 300);
      },
    });

    collapsibles.forEach(function(collapsible) {
      const collapsibleInputs = collapsible.querySelectorAll('input'),
            collapsibleHeader = collapsible.querySelector('[data-collapsible-header]'),
            collapsibleHeaderHeight = collapsibleHeader.offsetHeight;

      collapsible.style.minHeight = '' + collapsibleHeaderHeight + 'px';

      collapsibleInputs.forEach(function(input) {
        input.addEventListener('focus', function() {
          if (collapsibleHeader) {
            if (!collapsible.classList.contains('is-expanded')) {
              collapsibleHeader.click();
            }
          }
        }, true);
      });

      collapsible.classList.add('collapsible-init');
    });

    refreshPoppers();

    scroll.animateScroll(document.querySelector('body'));
  }

  function bindCustomNameInputs(inputs, attribute, dataAttribute) {
    inputs.forEach(function(input) {
      const customOptionParent = input.closest('[data-custom-parent]');

      if (customOptionParent) {
        const customOptions = customOptionParent.querySelectorAll('[' + dataAttribute + ']');

        if (customOptions.length > 0) {
          customOptions.forEach(function(option) {
            const optionId = option.getAttribute('id'),
                  optionLabel = customOptionParent.querySelector('[for="' + optionId + '"]');

            if (optionLabel) {
              const randomInt = Math.floor(Math.random() * 1000000),
                    queriedEl = document.getElementById(randomInt);

              option.setAttribute('id', randomInt);
              optionLabel.setAttribute('for', randomInt);
            }

            if (option.type == 'text') {
              option.value = '';
            }
          });

          function inputValueChange() {
            const value = input.value;

            if (value) {
              customOptions.forEach(function(option) {
                const nameTemplate = option.getAttribute(dataAttribute),
                      name = nameTemplate.replace('{value}', value);
  
                option.setAttribute(attribute, name);
                if (option.type == 'radio') {
                  option.disabled = false;
                } else if (option.type == 'checkbox') {
                  option.checked = true;
                }
              });
            } else {
              customOptions.forEach(function(option) {
                option.removeAttribute(attribute);
                option.checked = false;
                if (option.type == 'radio') {
                  option.disabled = true;
                }
              });
            }
          }
      
          input.addEventListener('change', inputValueChange);
          input.addEventListener('paste', inputValueChange);
          input.addEventListener('keyup', inputValueChange);

          input.value = '';

          let event;

          if (typeof(Event) === 'function') {
            event = new Event('change');
          } else {
            event = document.createEvent('Event');
            event.initEvent('change', true, true);
          }

          input.dispatchEvent(event);
        }
      }
    });
  }

  const body = document.querySelector('body'),
        cardToggles = document.querySelectorAll('[data-card-toggle]'),
        cardParentToggles = document.querySelectorAll('[data-parent-card-toggle]'),
        customNameInputs = document.querySelectorAll('[data-custom-name-input]'),
        customValueInputs = document.querySelectorAll('[data-custom-value-input]'),
        customOptionDuplicatesButtons = document.querySelectorAll('[data-duplicate-custom-option]'),
        conditionalFields = document.querySelectorAll('[data-conditional-name][data-conditional-value]'),
        sectionToggles = document.querySelectorAll('[data-section]'),
        sectionCompleteToggles = document.querySelectorAll('[data-section-complete]'),
        printButtons = document.querySelectorAll('[data-print]'),
        pdfButtons = document.querySelectorAll('[data-pdf]'),
        emailButtons = document.querySelectorAll('[data-email]'),
        dateInputs = document.querySelectorAll('.date-picker'),
        timeInputs = document.querySelectorAll('.time-picker'),
        modalExternalProceed = document.getElementById('modal-external-proceed');

  // Init rome inputs
  dateInputs.forEach(function(input) {
    rome(input, {
      inputFormat: 'MM-DD-YYYY',
      time: false,
    }).on('ready', function() {
      body.classList.add('rome-hidden');
    }).on('show', function() {
      setTimeout(function() {
        body.classList.remove('rome-hidden');
      }, 50);
    }).on('hide', function() {
      body.classList.add('rome-hidden');
    });
  });

  timeInputs.forEach(function(input) {
    rome(input, {
      date: false,
      inputFormat: 'LT',
      timeFormat: 'LT',
    }).on('ready', function() {
      body.classList.add('rome-hidden');
    }).on('show', function() {
      setTimeout(function() {
        body.classList.remove('rome-hidden');
      }, 50);
    }).on('hide', function() {
      body.classList.add('rome-hidden');
    });
  });

  // Init external link interstitials
  document.querySelectorAll('a').forEach(function(a) {
    const href = a.getAttribute('href');

    if (location.hostname !== a.hostname && a.hostname.length) {
      a.addEventListener('click', function(e) {
        e.preventDefault();

        MicroModal.show('modal-external');

        modalExternalProceed.addEventListener('click', function() {
          window.removeEventListener('beforeunload', handleUnload);
          window.location.href = href;
        });
      });
    }
  });

  // Conditional fields
  let conditionalNames = [];
  conditionalFields.forEach(function(field) {
    const conditionalName = field.getAttribute('data-conditional-name'),
          conditionalValue = field.getAttribute('data-conditional-value'),
          conditionalTarget = document.querySelector('[name="' + conditionalName + '"][value="' + conditionalValue + '"]');

    if (conditionalTarget) {
      if (!conditionalNames.includes(conditionalName)) {
        conditionalNames.push(conditionalName);
      }
    }

    field.classList.add('hidden');
  });

  conditionalNames.forEach(function(conditionalName) {
    const conditionalTargets = document.querySelectorAll('[name="' + conditionalName + '"]'),
          conditionalFields = document.querySelectorAll('[data-conditional-name="' + conditionalName + '"]');

    conditionalTargets.forEach(function(target) {
      target.addEventListener('change', function() {
        const activeTarget = document.querySelector('[name="' + conditionalName + '"]:checked'),
              conditionalValue = activeTarget.value,
              activeField = document.querySelector('[data-conditional-name="' + conditionalName + '"][data-conditional-value="' + conditionalValue + '"]');

        conditionalFields.forEach(function(field) {
          const radioInputs = field.querySelectorAll('input[type="radio"]'),
                checkboxInputs = field.querySelectorAll('input[type="checkbox"]'),
                textInputs = field.querySelectorAll('input[type="text"]');

          field.classList.add('hidden');

          radioInputs.forEach(function(input) {
            input.checked = false;
          });
          checkboxInputs.forEach(function(input) {
            input.checked = false;
          });
          textInputs.forEach(function(input) {
            input.value = '';
          });
        });

        if (activeField) {
          activeField.classList.remove('hidden');
        }

        refreshPoppers();
      });
    });
  });

  // Section toggles
  sectionToggles.forEach(function(toggle) {
    const sectionIndex = toggle.getAttribute('data-section'),
          sectionId = 'section-' + sectionIndex,
          section = document.getElementById(sectionId);

    if (section) {
      toggle.addEventListener('click', function() {
        if (section.nextElementSibling) {
          loadSection(section);
        } else {
          MicroModal.show('modal-complete');
        }
      });
    }
  });

  sectionCompleteToggles.forEach(function(toggle) {
    const sectionIndex = toggle.getAttribute('data-section-complete'),
          sectionId = 'section-' + sectionIndex,
          section = document.getElementById(sectionId);

    if (section) {
      toggle.addEventListener('click', function() {
        prepareResults();
        loadSection(section);
        MicroModal.close('modal-complete');
      });
    }
  });

  bindCustomNameInputs(customNameInputs, 'name', 'data-custom-name');
  bindCustomNameInputs(customValueInputs, 'value', 'data-custom-value');

  customOptionDuplicatesButtons.forEach(function(duplicateButton) {
    const duplicateId = duplicateButton.getAttribute('data-duplicate-custom-option'),
          customNameInputBindInputSelector = duplicateButton.getAttribute('data-duplicate-custom-option-input-selector'),
          customNameInputBindName = duplicateButton.getAttribute('data-duplicate-custom-option-name'),
          customNameInputBindAttribute = duplicateButton.getAttribute('data-duplicate-custom-option-attribute'),
          parentNode = duplicateButton.parentNode;

    if (duplicateId) {
      const duplicate = document.getElementById(duplicateId);

      if (duplicate) {
        duplicateButton.addEventListener('click', function() {
          if (duplicate.classList.contains('hide-me')) {
            duplicate.classList.remove('hide-me');
          } else {
            const duplicateSelecteds = duplicate.querySelectorAll('[type="radio"]:checked'),
                  newDuplicate = duplicate.cloneNode(true),
                  newDuplicateCustomInputs = newDuplicate.querySelectorAll(customNameInputBindInputSelector);

            newDuplicate.removeAttribute('id');
            parentNode.insertBefore(newDuplicate, duplicateButton);
            bindCustomNameInputs(newDuplicateCustomInputs, customNameInputBindName, customNameInputBindAttribute);

            if (duplicateSelecteds) {
              duplicateSelecteds.forEach(function(duplicateSelected) {
                const duplicateSelectedId = duplicateSelected.getAttribute('id'),
                    originallySelected = document.getElementById(duplicateSelectedId);

                if (originallySelected) {
                  originallySelected.click();
                }
              });
            }
          }

          let event;

          if (typeof(Event) === 'function') {
            event = new Event('resize');
          } else {
            event = document.createEvent('Event');
            event.initEvent('resize', true, true);
          }

          window.dispatchEvent(event);
        });
      }
    }
  });

  // Card toggles
  cardToggles.forEach(function(toggle) {
    const toggleInputs = toggle.querySelectorAll('input');

    toggleInputs.forEach(function(input) {
      input.addEventListener('focus', function() {
        toggle.classList.add('active');
      }, true);
    });

    toggle.addEventListener('click', function() {
      if (!toggle.classList.contains('active')) {
        toggle.classList.add('active');
      }
    });
  });

  cardParentToggles.forEach(function(toggle) {
    toggle.addEventListener('click', function(e) {
      e.preventDefault();
      e.stopPropagation();

      const parentCard = toggle.closest('[data-card-toggle]');

      if (parentCard) {
        const inputs = parentCard.querySelectorAll('input');

        parentCard.classList.remove('active');
        // inputs.forEach(function(input) {
        //   if (input.checked) {
        //     input.checked = false;
        //   }
        // });
      }
    });
  });

  // Print button handler
  printButtons.forEach(function(button) {
    const formId = button.getAttribute('data-print'),
          form = document.getElementById(formId);

    if (form) {
      button.addEventListener('click', function() {
        const action = form.getAttribute('data-print-action');
        
        form.setAttribute('action', action);
        form.submit();
        form.setAttribute('action', '');
      });
    }
  });

  // PDF button handler
  pdfButtons.forEach(function(button) {
    const formId = button.getAttribute('data-pdf'),
          form = document.getElementById(formId);

    if (form) {
      button.addEventListener('click', function() {
        const action = form.getAttribute('data-pdf-action');
        
        form.setAttribute('action', action);
        form.submit();
        form.setAttribute('action', '');
      });
    }
  });

  // Email button handler
  emailButtons.forEach(function(button) {
    const formId = button.getAttribute('data-email'),
          form = document.getElementById(formId);

    if (form) {
      button.addEventListener('click', function() {    
        button.setAttribute('data-text', button.innerText);
        button.innerText = 'Submitting...';
        button.disabled = true;
        grecaptcha.execute();
      });
    }
  });

  // Email recaptcha handler
  const recaptchaId = 'email-recaptcha',
        recaptcha = document.getElementById(recaptchaId);

  recaptcha.addEventListener('email-recaptcha-success', function () {
    const xhr = new XMLHttpRequest(),
          formId = 'form',
          form = document.getElementById(formId),
          formEmailFields = form.querySelectorAll('[name="email"]'),
          formEmailButtons = document.querySelectorAll('[data-email="' + formId + '"][disabled][data-text]');

    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        console.log('success', xhr);
      } else {
        console.log('error', xhr);
      }

      MicroModal.close('modal-email');

      formEmailFields.forEach(function(field) {
        field.value = '';
      });

      formEmailButtons.forEach(function(button) {
        button.innerText = button.getAttribute('data-text');
        button.disabled = false;
      });

      grecaptcha.reset();
    };

    if (form) {
      const action = form.getAttribute('data-email-action'),
            params = serialize(form);

      if (action) {
        xhr.open('POST', action, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send(params);
      }
    }
  }, false);
});
